





































































































































































































































































































import { Navigation } from "@/mixins/navigation";
import { CustomDate } from "@/mixins/custom-date";
import Component, { mixins } from "vue-class-component";
import { Watch } from "vue-property-decorator";
import Loader from "@/components/General/Loader.vue";
import Empty from "@/components/General/Empty.vue";
import { Notification } from "@/models/notification.interface";
import { UserValidations } from "@/mixins/user-validations";
import PageTitle from "@/components/General/PageTitle.vue";
import { FormValidations } from "@/mixins/form-validations";
import { StyleCheck } from "@/mixins/style-check";

@Component({
  components: {
    Loader,
    Empty,
    PageTitle,
  },
})
export default class UserWithdrawals extends mixins(
  Navigation,
  UserValidations,
  FormValidations,
  CustomDate,
  StyleCheck
) {
  search = "";
  loader = false;
  loading = false;
  dialog = false;
  dialogMessage = false;
  amount = 0;
  pagination = {
    itemsPerPage: parseInt(process.env.VUE_APP_DEFAULT_PAGINATION),
    totalItems: 0,
    page: 1,
  };

  private async created() {
    this.loader = true;

    await this.getUserWithdrawals(
      this.$route.params.page,
      this.pagination.itemsPerPage
    );

    await this.getUserBalance();

    await this.$store.dispatch("users/getConfiguredAccounts");

    this.loader = false;
    window.scrollTo(0, 0);
  }

  private async getUserBalance() {
    await this.$store
      .dispatch("users/getUserBalanceById", this.userID)
      .catch(() => {
        const Error: Notification = {
          message: this.$t("Users.fetchError.userBalance", {
            name: this.$route.params.id,
          }) as string,
          timeout: this.$constants.NOTIFICATION_TIMEOUT.ERROR,
          top: true,
          bottom: false,
          left: false,
          right: false,
          currentPath: this.$route.fullPath,
          error: true,
        };

        this.$store.dispatch("notifications/showNotification", Error);
      });
  }

  private get withdrawals() {
    let response = this.$store.getters["withdrawal/getUserWithdrawals"];
    this.pagination.totalItems = response.totalItems;
    this.pagination.page = parseInt(response.currentPage);

    if (response?.withdrawals) {
      return response?.withdrawals;
    } else {
      return [];
    }
  }

  private get configuredAccounts() {
    return this.$store.getters["users/getConfiguredAccounts"];
  }

  private openDialog() {
    if (this.configuredAccounts) {
      this.dialog = true;
    } else {
      this.dialogMessage = true;
    }
  }

  private get totalPages() {
    const total =
      this.pagination.totalItems /
      Number(process.env.VUE_APP_DEFAULT_PAGINATION);
    if (total > Math.round(total)) {
      return Math.round(total) + 1;
    } else {
      return Math.round(total);
    }
  }

  private get userID(): any {
    let user = this.$store.getters["authentication/getUser"];
    if (user) {
      return user.id;
    } else {
      return {};
    }
  }

  private get userBalance(): any {
    return this.$store.getters["users/getUserBalance"];
  }

  private setMaxAmount() {
    this.amount = this.userBalance.amount;
  }

  private closeRequest() {
    this.dialog = false;
    this.amount = 0;
  }

  private async getUserWithdrawals(
    page: string | number,
    size: number,
    withdrawal_id = ""
  ): Promise<void> {
    await this.$store
      .dispatch("withdrawal/getUserWithdrawals", {
        page,
        size,
        withdrawal_id: withdrawal_id,
      })
      .catch(() => {
        const Error: Notification = {
          message: this.$tc("Views.urw-e1"),
          timeout: this.$constants.NOTIFICATION_TIMEOUT.ERROR,
          top: true,
          bottom: false,
          left: false,
          right: false,
          currentPath: this.$route.fullPath,
          error: true,
        };

        this.$store.dispatch("notifications/showNotification", Error);
      });
  }

  @Watch("$route.params", { deep: true })
  private async handler() {
    this.loader = true;
    await this.getUserWithdrawals(
      this.$route.params.page,
      this.pagination.itemsPerPage
    );
    this.loader = false;
  }

  //Search by ID
  @Watch("search")
  private async searchUserWithdrawalByID() {
    if (this.search == null) {
      this.search = "";
    }

    await this.getUserWithdrawals(
      this.pagination.page,
      this.pagination.itemsPerPage == -1
        ? this.pagination.totalItems
        : this.pagination.itemsPerPage,
      this.search
    );
  }

  private changePage() {
    this.navigate("/user/withdrawals/" + this.pagination.page);
  }

  private get checkWithdrawalRequest() {
    if (this.amount > 0 && this.amount <= this.userBalance.amount) {
      return true;
    } else {
      return false;
    }
  }

  private async requestWithdrawal() {
    this.loading = true;
    await this.$store
      .dispatch("withdrawal/requestWithdrawal", this.amount)
      .then(async () => {
        await this.getUserWithdrawals(
          this.$route.params.page,
          this.pagination.itemsPerPage
        );

        await this.getUserBalance();

        const Success: Notification = {
          message: this.$tc("Views.urw-17"),
          timeout: this.$constants.NOTIFICATION_TIMEOUT.SUCCESS,
          top: true,
          bottom: false,
          left: false,
          right: false,
          currentPath: this.$route.fullPath,
          error: false,
        };

        this.$store.dispatch("notifications/showNotification", Success);
        this.closeRequest();
      })
      .catch(() => {
        const Error: Notification = {
          message: this.$tc("Views.urw-e2"),
          timeout: this.$constants.NOTIFICATION_TIMEOUT.ERROR,
          top: true,
          bottom: false,
          left: false,
          right: false,
          currentPath: this.$route.fullPath,
          error: true,
        };

        this.$store.dispatch("notifications/showNotification", Error);
      })
      .finally(() => {
        this.loading = false;
      });
  }
}
